<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table">
            
             <div class="main_header_ctas d-flex align-items-center justify-content-between">
            <button
              v-if="multipuleIds.length >= 1"
              @click="deleteBulk"
              class="
                btn
                border-0
                px-5
                fw-bold
                text-white
                btn btn-primary
                waves-effect waves-light
                w-md
                primary-button
              "
            >
              Delete All
            </button>
            <!-- <button
              class="
                btn
                border-0
                px-5
                fw-bold
                text-white
                btn btn-primary
                waves-effect waves-light
                w-md
                primary-button
              "
            >
              Add New Review
            </button> -->
            </div>
            <div
              class="
                d-flex
                justify-content-end
                flex-sm-nowrap flex-wrap
                gap-3
                my-3
              "
            >
            <div class="d-flex justify-content-between w-100">
                <div class="plan_filter pe-2 py-0">
                  <select
                    @change="filteredData($event)"
                    class="form-select table-light pe-5 py-75rem"
                    id="floatingSelectGrid"
                    aria-label="select"
                  >
                    <option value="all" selected="">All Reviews</option>
                    <option value="Omaha">Omaha Reviews</option>
                    <option value="Google">Google Reviews</option>
                    <option value="Capterra">Capterra Reviews</option>
                  </select>
                </div>
              </div></div>
            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0"
              >
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          @change="selectAllRecord"
                          v-model="selectAll"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th>Title</th>
                    <th>User Name</th>
                    <th>Review Type</th>
                    <th>Rating</th>
                    <th>Created Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr colspan="5" v-if="loading">
                    <td colspan="6">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(review, n) in reviews" :key="n">
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          v-model="multipuleIds"
                          :value="review.id"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <td>
                      <span>{{ review.title }}</span>
                    </td>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <div v-if="review.user">
                            <img v-if="!review.user.avatar"
                            src="../../../../../public/avatar.jpg"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          <img v-else
                          :src="review.user.avatar"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          </div>
                          <span>{{
                            getSafe(() => review.user.full_name, "N/A")
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <!-- <td>Amet minim mollit non deserunt ullamco est sit...</td> -->

                    <td>
                      {{
                        getSafe(
                          () =>review.review_type,
                          "N/A")
                        
                      }}
                    </td>
                    <td>
                      {{
                        getSafe(
                          () =>
                            Math.ceil(
                              review.listing_review_ratings_avg_rating_number
                            ),
                          "N/A"
                        )
                      }}
                      stars
                    </td>
                    <td>
                      {{ getSafe(() => review.updated_at, "N/A") }}
                    </td>
                    <td>
                      <div class="d-flex gap-2">
                        <button class="p-0 bg-transparent border-0">
                          <a
                            @click="listingReviews(review.text)"
                            href="javascript: void(0);"
                            v-b-modal.modal-4
                            ><span><i class="uil-eye"></i></span
                          ></a>
                        </button>
                        <!-- <button class="p-0 bg-transparent border-0">
                          <span><i class="uil-edit"></i></span>
                        </button> -->
                        <a
                          @click="deleteReview(review.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-danger"
                            ><i class="uil-trash-alt"></i
                          ></span>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
    <Reviews :reviews="reviewByPerson" />
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Reviews from "../../../../components/reviews.vue";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Reviews",
    meta: [
      {
        name: "reviews",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Reviews,
    Layout,
    PageHeader,
  },
  data() {
    return {
      multipuleIds: [],
      selectAll: false,
      reviewByPerson: null,
      reviews: null,
      title: "Reviews",
      loading: true,
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Reviews",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allReviews();
  },
  methods: {
    randerData(response) {
      this.reviews = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    allReviews() {
      this.$axios
        .get("reviews")
        .then((response) => {
          // console.log(response.data.data);
          // this.reviews = response.data.data;
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    listingReviews(review) {
      console.log(review);
      this.reviewByPerson = review;
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("reviews/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteReview(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allReviews();
          }
        }
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.reviews.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    filteredData(event){
      let filter = event.target.value;
      if (filter == 'all') {
        this.allReviews();
      }else{
        let header = {
          filter: filter,
        };
        this.$axios
          .post("filterd-reviews", header)
          .then((response) => {
            setInterval(this.randerData(response, 1500));
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    deleteBulk() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$axios
            .post("delete-reviews-bulk", { ids: this.multipuleIds })
            .then((response) => {
              this.allReviews();
              this.triggerSwal(response.data.message, "success");
              this.selectAll = false;
              this.multipuleIds = [];
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      });
    },
  },
};
</script>